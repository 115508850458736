import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import swal from 'sweetalert';
import site_logo from '../assets/images/loader-logo.png';
import Footer from './Footer';
class CustomerHeader extends Component {
    constructor(){
        super();
        this.state = {
            redirectToReferrer: false,
            base_url:api_url.base_url,
            site_logo:'',
        };
        this.Logout = this.Logout.bind(this);
    }
    componentDidMount(){
        var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo
                })

            }
        })
    }
    Logout(){
        localStorage.removeItem('customer_id');
        this.setState({redirectToReferrer:true})
    }
    render(){
        if(this.state.redirectToReferrer)
        {
            return(<Redirect to='/from-me-shipment' />)
        }
        return(

            <div className="row top_head co-one" id="order_listing">
                <div className="col-sm-2 back_btn">
                    <a className="logout-btn" style={{cursor:'pointer'}} onClick={()=>this.Logout()}>
                        <svg width="25px" height="25px"  viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#fff"/></svg>
                    </a>
                </div>
                <div className="col-sm-8 page_title">
                    <h4>{this.props.title}</h4>
                </div>
                {/* <div className="col-sm-2 right_logo">
                    <img src={site_logo} alt=""/>
                </div> */}
            </div>
        )
    }
}
export default CustomerHeader;
