import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import swal from 'sweetalert';
import site_logo from '../assets/images/loader-logo.png';
import Footer from './Footer';
import ReactWhatsapp from 'react-whatsapp';
class CustomerHeader extends Component {
    constructor(){
        super();
        this.state = {
            redirectToReferrer: false,
            base_url:api_url.base_url,
            site_logo:'',
            company_name:'',
            whats_app:''
        };
        this.Logout = this.Logout.bind(this);
    }
    componentDidMount(){
        var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo,
                    company_name:result.company_name,
                })

            }
        })
        var WHATSAPPURL = api_url.base_url + 'customer_api.php?type=whatsappNo';
        fetch(WHATSAPPURL)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    whats_app:result.number,
                })

            }
        })
    }
    Logout(){
        localStorage.removeItem('customer_id');
        this.setState({redirectToReferrer:true})
    }
    render(){
        if(this.state.redirectToReferrer)
        {
            return(<Redirect to='/from-me-shipment' />)
        }
        return(
        <>
            <div className="row top_head co-one"id="mian_home">
      <div className="col-sm-2 back_btn toggle_Btn">

      </div>
      <div className="col-sm-8 page_title">
        <h4>{this.state.company_name}</h4>
      </div>
      <div className="col-sm-2 right_logo">
          <img src={site_logo} alt=""/>
        </div>
    </div>
    {/* <div className="whatsapp">
    <a target="_blank" href="https://wa.me/201050007887"><i className="fa fa-whatsapp"></i></a>
        
      </div> */}
    </>
        )
    }
}
export default CustomerHeader;
