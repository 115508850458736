import React, {Component} from 'react';
import { Redirect, Link} from 'react-router-dom';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
import lang_icon from '../assets/images/lang-black-icon.png';
class Otp extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        otp:''
    };
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
}
componentDidMount(){


}


login()
{
  if(this.state.otp ==='')
  {
    swal('OTP is required.')
  }else{
    if(this.state.otp==localStorage.getItem('otp_code')){
    this.setState({loading:true})
            let signupData = {
              otp_code:this.state.otp,
              customer_id:localStorage.getItem('pending_customer_id')
        }
        const addUrl = this.state.base_url+'customer_api.php?type=verify_customer';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(signupData)
        })
        .then((response) => response.json()
            .then((result) => {
               if(result.response > 0)
               {
                localStorage.setItem('customer_id',localStorage.getItem('pending_customer_id'));
                this.setState({redirectToReferrer: true,loading:false},()=>{
                   // window.location.reload();
                });

               }else{
                 swal('Error Occured!.');
                 this.setState({loading:false})
               }
        }))
        .catch((error) => {
          this.setState({loading:false})
            });
      
    }else{
      swal('Wrong OTP.')
    }
  }


}

onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render(){
  
    const lang =JSON.parse(localStorage.getItem("lang"));
  // console.log(localStorage.getItem("lang_name"))
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='dashboard' />)
  }
return(

<React.Fragment>
    <div id="page-name">
        <CommonHeader />
        <div className="main_body body_img_bg">
            <div className="login_screen fix_screen" id="register_screen" style={{padding: '63px 0 0 !important'}}>

                <div className="row">
                    <div className="col-lg-12 fix_logo_f register_info">
                        <h3>{lang.lets_start}</h3>
                        <p>نرجو ادخال الرمز التحقق المرسل الى هاتفك</p>
                    </div>
                </div>
                <div className="track_shipments" id="login_desktop">
                    <div className="form_box get_quote">
                        <input type="number" placeholder="OTP " required="required" onChange={this.onChange} name="otp"/>
                        <svg style={{top: '7px'}} className="search_box" viewBox="0 0 24 24"><path d="M21 9v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9c0-1.11.603-2.08 1.5-2.598l-.003-.004l8.001-4.62l8.007 4.623l-.001.003A2.999 2.999 0 0 1 21 9zM3.717 7.466L11.5 12.52l7.783-5.054l-7.785-4.533l-7.781 4.533zm7.783 6.246L3.134 8.28A1.995 1.995 0 0 0 3 9v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V9c0-.254-.047-.497-.134-.72L11.5 13.711z" fill="#626262"/></svg>
                    </div>
                    <div id="signup_box">
                    {
                       // <button onClick={()=>this.login()} type="button" className="contact_btn login_btn_ mb-0 ml-2 mt-0 mr-2 w-75">Sign In</button>
                    }
                        <button className="contact_btn login_btn_ mb-0 ml-2 mt-0 mr-2 w-75"  type="button" onClick={()=>this.login()} disabled={this.state.loading}>
                          {this.state.loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {this.state.loading && <span>{lang.sign_in}</span>}
                          {!this.state.loading && <span>{lang.sign_in}</span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</React.Fragment>
  )
}
}
export default Otp;
