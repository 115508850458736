import React from 'react';
import './App.css';
import {HashRouter as Router, withRouter} from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home';
import Test from './container/Test';
import TrackParcel from './container/TrackParcel';
import PickupRequest from './container/PickupRequest';
import TrackShippment from './container/TrackShippment';
import TrackDetails from './container/TrackDetails';
import TrackDetail from './container/TrackDetail';
import TrackingDetail from './container/TrackingDetail';
import ContactUs from './container/ContactUs';
import RatesTransit from './container/RatesTransit.js';
import TrackListing from './container/TrackListing.js';
import Login from './container/Login.js';
import Signup from './container/Signup.js';
import Toshipment from './container/Toshipment.js';
import ToshipmentDate from './container/ToshipmentDate.js';
import FromShipment from './container/FromShipment.js';
import Booking from './container/Booking.js';
import OrderListing from './container/OrderListing.js';
import Dashboard from './container/Dashboard.js';
import OrderDetailNew from './container/Order-detail.js';
import Logout from './component/Logout.js';
import CustomerSignup from './container/CustomerSignup';
import Otp from './container/Otp';
// import NoPage from './container/NoPage';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/pickup-request" component={PickupRequest}/>
          <Route path="/track-shipment" component={TrackShippment}/>
          <Route path="/track-details" component={TrackDetails}/>
          <Route path="/track-detail/:track_no" component={TrackDetail}/>
          <Route path="/tracking-detail/:track_no" component={TrackingDetail}/>
          <Route path="/contact-us" component={ContactUs}/>
          <Route path="/rates" component={RatesTransit}/>
          <Route path="/track-listing" component={TrackListing}/>
          <Route path="/login" component={Login}/>
          <Route path="/signup" component={Signup}/>
          <Route path="/tome-shipment" component={Toshipment}/>
          <Route path="/tome-shipment-date" component={ToshipmentDate}/>
          <Route path="/from-me-shipment" component={FromShipment}/>
          <Route path="/booking" component={Booking}/>
          <Route path="/orders" component={withRouter(OrderListing)}/>
          <Route path="/logout" component={Logout}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/order-detail/:track_no" component={OrderDetailNew}/>
          <Route path="/customer-signup" component={CustomerSignup}/>
          <Route path="/track-parcel" component={TrackParcel}/>
          <Route path="/test" component={Test}/>
          <Route path="/otp" component={Otp}/>
		</Switch>
		</Router>
    </React.Fragment>
  );
}

export default App;
