export const arabic = {
  tracking_history: "سجل التتبع",
  busingess_dashboard: "لوحة تحكم الاعمال",
  enter_your_tracking_code: "أدخل رمز التتبع الخاص بك",
  track_order: "تتبع الطلب",
  get_quote: "الحصول على عرض سعر",
  contact_us: "تواصل معنا",
  service_type: "نوع الخدمة",
  city_area: "المنطقة/المدينة",
  name: "الاسم",
  phone: "الهاتف",
  phone_number: "رقم الهاتف",
  address: "العنوان",
  pickup_address: "عنوان الاستلام",
  previous: "السابق",
  next: "التالي",
  delivery_detail: "تفاصيل التوصيل",
  select: "اختيار",
  areas: "المناطق",
  receiver_name: "اسم المستلم",
  receiver_phone: "جوال المستلم",
  receiver_address: "عنوان المستلم",
  shipment_details: "تفاصيل الشحنة",
  item_detail: "تفاصيل البند",
  details: "التفاصيل",
  special_instuction: "تعليمات خاصة",
  weight: "الوزن (كجم)",
  no_of_pieces: "عدد القطع",
  cod_amount: "مبلغ الدفع عند الاستلام",
  loading: "جاري التحميل ...",
  submit: "ارسال",
  home: "الرئيسية",
  booking: "حجز",
  order: "اطلب",
  orders: "طلبات",
  pickup_detail: "تفاصيل الاستلام",
  enter_your_name: "ادخل اسمك",
  enter_your_phone: "رقم الجوال",
  your_message: "رسالتك هنا",
  sign_up: "التسجيل",
  personal_info: "المعلومات الشخصية",
  company_name: "اسم الشركة",
  person_of_contact: "المسؤول عن التواصل",
  email: "البريد الالكتروني",
  select_city: "اختار المدينة",
  cnic: "الهوية الوطنية/رقم الاقامة",
  cnic_copy: "صورة الهويةالوطنية/الاقامة",
  bank_information: "معلومات البنك",
  bank_name: "اسم البنك",
  account_title: "عنوان الحساب",
  account_number: "رقم الحساب",
  branch_name: "اسم الفرع",
  branch_code: "رمز الفرع",
  swift_code: "سويفت كود",
  iban: "ايبان",
  password: "كلمة المرور",
  confirm_password: "تاكيد كلمة المرور",
  dashboard: "لوحة التحكم",
  lets_get_a_quick: "دعنا نلقي نظرة عامة سريعة ...",
  pending_cod_amount: "مبلغ الدفع عند الاستلام معلق",
  total_cod: "اجمالي الدفع عند الاستلام",
  lets_start: "هيا بنا نبدأ",
  plz_enter_requested_info: "الرجاء إدخال المعلومات المطلوبة.",
  sign_in: "تسجيل الدخول",
  dont_have_accunt: "ليس لديك حساب؟",
  taslim_net: "عون",
  tracking_no: "رقم التتبع",
  sender_information: "معلومات المرسل",
  receiver_information: "معلومات المستلم",
  order_iformation: "معلومات الطلب",
  date: "التاريخ",
  status: "الحالة",
  order_list: "قائمة الطلبات",
  pickup_city: "مدينة الاستلام",
  account_name: "اسم الحساب",
  bussiness_name: "اسم العمل",
  destination: "الوجهة",
  parcel_weight: "وزن الارسالية",
  delivery_fees: "تعرفة التوصيل",
  amount: "المبلغ",
  no_record_found: "لا يوجد سجلات",
  pickup_city_select: "يرجى تحديد مدينة الاستلام",
  rate_transit: "الأسعار والعبور",
  select_service_type: "الرجاء تحديد نوع الخدمة",
  select_distination: "الرجاء تحديد مدينة الوجهة",
  already_account: "هل لديك حساب؟",
  log_in: "تسجيل الدخول",
  soko_joint: "عون",
  to_me: "الي",
  from_me: "مني",
  rates_transit: "الأسعار والعبور",
  pickup_request: "طلب الاستلام",
  track_shipment: "تتبع الشحنات",
  track_ship_info: "تتبع معلومات الشحنه",
  tracking_result: "نتائج التتبع",
  shipper_info: "معلومات الشاحن ",
  shipper: "الشاحن",
  origin: "أصل",
  consigne_info: "معلومات المرسل إليه",
  save: "حفظ",
  track_info: "معلومات التتبع",
  tracking_list: "قائمة التتبع",
  track_your_pakage: "تتبع شحنتك هنا",
  track_up_to: "تتبع ما يصل إلى 10 أرقام في المرة الواحدة. مفصولة بفاصلة (،)",
  let_us_know: "أخبرنا بتعليقاتك أو استفساراتك أو مشكلاتك المتعلقة بالتطبيق أو الخصائص.",
  logo_img: "صورة الشعار",
  shipping_info: "معلومات الشحن",
  web_link: "رابط الموقع",
  account_nature: "حدد طبيعة الحساب",
  cash_on_deli: "الدفع عند الاستلام",
  coprate_invoices: "فواتير الشركات",
  product_type: "نوع المنتج",
  apparel: "ثياب",
  auto_pants: "قطع غيار السيارات",
  expected_avrg: "متوسط ??الشحنات المتوقعة",
  month: "شهر",
  accessories: "اكسسوارات",
  gadgets: "ادوات",
  cosmetics: "مستحضرات التجميل",
  jewellry: "مجوهرات",
  stationary: "ادوات مكتبية",
  handicraft: "الحرف اليدوية",
  footwear: "الأحذية",
  organic: "عضوي",
  health_pro: "المنتجات الصحية",
  applience_electronics: "الأجهزة أو الإلكترونيات",
  home_decore: "ديكور المنزل أو العناصر الداخلية",
  toys: "العاب",
  fitness: "عناصر اللياقة البدنية",
  market_place: "المتجر",
  document: "مستندات",
  letter: "رسائل",
  other: "اخرى",
  order_src: "مصدر الطلب:",
  dream_window: "عون",
  boumaref: "عون",
  tracking: "تتبع",
  scan_pickup: "مسح الاستلام",
  scan_delivery: "مسح التوصيل",
  search_here: "ابحث هنا",
  kg: "كجم",
  otp_msg: " نرجو ادخال الرمز التحقق المرسل الى هاتفك",
  track_parcel: "تتبع الطرود",
  register:"تسجيل حساب جديد"
}