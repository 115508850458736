export const english = {
    tracking_history: "Tracking History",
    busingess_dashboard: "Business Dashboard",
    enter_your_tracking_code: "Enter your tracking code",
    track_order: "Track Order",
    get_quote: "Get Quote",
    contact_us: "Contact Us",
    service_type: "Service type ",
    city_area: "City/Area",
    name:"Name",
    phone: "Phone",
    phone_number:"Phone Number",
    address: "Address",
    pickup_address: "Pickup Address",
    previous: "Previous",
    next: "Next",
    delivery_detail: "Delivery Detail",
    select: "Select",
    areas: "Areas",
    receiver_name: "Receiver Name",
    receiver_phone: "Receiver Phone",
    receiver_address:"Receiver Address",
    shipment_details: "Shipment Details",
    item_detail: "Item Detail",
    details: "Details",
    special_instuction: "Special Instructions",
    weight: "Weight (Kg)",
    no_of_pieces: "No. of Pieces",
    cod_amount: "COD Amount",
    loading: "Loading...",
    submit: "Submit",
    home: "Home",
    booking: "Booking",
    order: "Order",
    orders: "orders",
    pickup_detail:"Pickup Details",
    enter_your_name: "Enter Your Name",
    enter_your_phone: "Mobile Number",
    your_message: "Your Message Here",
    sign_up: "Sign Up",
    personal_info: "Personal Information",
    company_name: "Company Name",
  	person_of_contact: "Person of Contact",
  	email: "Email",
  	select_city: "Select City",
  	cnic: "Egypt ID Number",
  	cnic_copy: "CNIC Copy",
  	bank_information: "Bank Information",
  	bank_name: "Bank Name",
  	account_title: "Account Title",
  	account_number: "Account Number",
  	branch_name: "Branch Name",
  	branch_code: "Branch Code",
  	swift_code: "Swift Code",
  	iban: "IBAN",
  	password: "Password",
  	confirm_password:"Confirm Password",
  	dashboard: "Dashboard",
  	lets_get_a_quick: "Let's get a quick overview...",
  	pending_cod_amount: "Pending COD Amount",
  	total_cod: "Total COD Amount",
  	lets_start: "Let's get started",
  	plz_enter_requested_info: "Please enter the requested information.",
  	sign_in: "Sign In",
  	dont_have_accunt: "Don't have account?",
  	taslim_net: "Taslim.net",
  	tracking_no: "Tracking Number",
  	sender_information:"Sender information",
  	receiver_information: "Receiver information",
  	order_iformation: "Order information",
  	date: "Date",
  	status:"Status",
  	order_list: "Orders List",
  	pickup_city: "Pickup City",
  	account_name: "Account Name",
  	bussiness_name: "Business Name",
  	destination: "Destination",
  	parcel_weight: "Parcel Weight",
  	delivery_fees: "Delivery Fees",
  	amount: "Amount",
  	no_record_found: "No Record Found",
    pickup_city_select: "Please Select Pickup City",
    rate_transit: "Rates & Transit",
    select_service_type: "Please select service type",
    select_distination: "Please select destination city",
    already_account: "Already have an account?",
    log_in: "Login",
    soko_joint: "SokoJoint",
    to_me: "To me",
    from_me: "From me",
    rates_transit:"Rates and  transit",
    pickup_request: "Pickup Request",
    track_shipment: "Track Shipments",
    track_ship_info:"Track Shipment Info",
    tracking_result: "Tracking Results",
    shipper_info: "Shipper Information ",
    shipper:"Shipper",
    origin: "Origin",
    consigne_info:"Consignee Information",
    save: "Save",
    track_info:"Track Info",
    tracking_list:"Tracking Listing",
    track_your_pakage:"Track Your Package Here",
    track_up_to:"Track up to 10 numbers at a time. Separate by a comma (,)",
    let_us_know:"Let us know your feedback,queries or issues regarding app or features.",
    logo_img: "Logo Image",
    shipping_info: "Shipping Information",
    web_link: "Website Url",
    account_nature:"Select Nature of Account",
    cash_on_deli: "Cash on  Delivery",
    coprate_invoices: "Corporate Invoicing",
    product_type: "Product Type",
    apparel: "Apparel",
    auto_pants: "Automotive Pants",
    expected_avrg: "Expected Avg Shipments",
    month: "Month",
    accessories: "Accessories",
    gadgets:"Gadgets",
    cosmetics: "Cosmetics",
    jewellry:"Jewellry",
    stationary:"Stationary",
    handicraft: "Handicrafts",
    footwear: "Footwear",
    organic: "Organic",
    health_pro: "Health Products",
    applience_electronics: "Appliances or Electronics",
    home_decore:"Home Decor or Interior items",
    toys: "Toys",
    fitness: "Fitness items",
    market_place: "MarketPlace",
    document:"Document",
    letter: "Letters",
    other:"Others",
    order_src: "Order Source:",
    dream_window:"Dream Windows",
    boumaref:"Boumaref",
    tracking: "Tracking",
    scan_pickup:"Scan Pickup",
    scan_delivery: "Scan Delivery",
    search_here : "Search Here",
    register:"Register",
    kg:"Kg",
    otp_msg:"نرجو ادخال الرمز التحقق المرسل الى هاتفك"




}
